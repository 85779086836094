<template>
  <CFormLabel for="input-rolle" v-if="showLabel">{{ label }}</CFormLabel>
  <CInputGroup class="mb-3 formelements-rolle">
    <CFormSelect
      :aria-label="label"
      :options="roles"
      :invalid="vld.$invalid"
      :valid="vld.$dirty"
      v-model="local"
      @blur="vld.$commit"
    ></CFormSelect>
    <CFormFeedback invalid v-if="vld.$invalid">
      Bitte geben Sie eine gültige Benutzerrolle an.
    </CFormFeedback>
  </CInputGroup>
</template>

<script>
export default {
  name: 'formelements-rolle',
  props: {
    label: {
      default: 'Benutzerrolle',
      type: String
    },
    showLabel: {
      default: true,
      type: Boolean
    },
    vld: {
      type: Object,
      required: true
    },
    modelValue: String
  },
  computed: {
    local: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    }
  },
  data () {
    return {
      roles: [
        { value: 'ROLE_USER', label: '1-User' },
        { value: 'ROLE_EDITOR', label: '2-Editor' },
        { value: 'ROLE_SUPERUSER', label: '3-Superuser' },
        { value: 'ROLE_ADMIN', label: '4-Administrator' }
      ]
    }
  }
}
</script>
