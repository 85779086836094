<template>
  <CFormLabel for="input-boxNo" v-if="showLabel">{{ label }}</CFormLabel>
  <CInputGroup class="mb-3 formelements-text">
    <CFormInput
      id="input-boxNo"
      :invalid="vld.$invalid"
      :valid="vld.$dirty"
      v-model="local"
      @blur="vld.$commit"
    />
    <CFormFeedback invalid v-if="vld.$invalid">
      Pflichtfeld, bitte tätigen Sie eine Eingabe.
    </CFormFeedback>
  </CInputGroup>
</template>

<script>
export default {
  name: 'BoxNo',
  props: {
    label: {
      default: 'Text',
      type: String
    },
    showLabel: {
      default: true,
      type: Boolean
    },
    vld: {
      type: Object,
      required: true
    },
    modelValue: String
  },
  computed: {
    local: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    }
  }
}
</script>
