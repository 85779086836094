<template>
  <CRow>
    <CCol :sm="12">
      <CCard>
          <h5>
            <CIcon size="lg" name="cil-plus"/>
            Neuer Benutzer
          </h5>
        <CCardBody>
          <CForm @submit.prevent="neuerBenutzer" autocomplete="off">
            <input-email v-model="form.email" :vld="v$.form.email" label="E-Mail *"/>
            <input-password v-model="form.password" :vld="v$.form.password" label="Passwort *"/>
            <input-rolle v-model="form.rolle" :vld="v$.form.rolle" label="Rolle/Level *"/>
            <input-text v-model="form.name" :vld="v$.form.name" label="Name *"/>
            <input-box-no v-model="form.boxNo" :vld="v$.form.boxNo" label="Kühlbox-Nr."/>
            <hr/>
            <input-submit  class="mt-4" label="Neuen Benutzer anlegen" :vld="v$"/>
          </CForm>

          <FormError v-if="formError" :errormessage="formError"/>
        </CCardBody>

      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { useToast } from 'vue-toastification'
import { required, email } from '@vuelidate/validators'
import FormError from '@/components/form-elements/Error'
import inputText from '@/components/form-elements/Text'
import inputEmail from '@/components/form-elements/Email'
import inputRolle from '@/components/form-elements/Rolle'
import inputBoxNo from '@/components/form-elements/BoxNo'
import inputPassword from '@/components/form-elements/Password'
import inputSubmit from '@/components/form-elements/Submit'

export default {
  name: 'app-user-neu',
  components: {
    inputText,
    inputEmail,
    inputRolle,
    inputBoxNo,
    inputPassword,
    inputSubmit,
    FormError
  },
  setup () {
    return {
      v$: useVuelidate(),
      toast: useToast()
    }
  },
  validations () {
    return {
      form: {
        name: {
          required
        },
        email: {
          required, email
        },
        rolle: {
          required
        },
        boxNo: {
        },
        password: {
          required
        }
      }
    }
  },
  data () {
    return {
      formError: null,
      form: {
        name: '',
        email: '',
        rolle: 'ROLE_USER',
        boxNo: '',
        password: ''
      }
    }
  },
  methods: {
    neuerBenutzer: function () {
      const self = this
      this.axios.post('/user/post', {
        name: self.form.name,
        email: self.form.email,
        roles: self.form.rolle,
        boxNo: self.form.boxNo,
        plainPassword: self.form.password
      }).then(() => {
        self.form.name = ''
        self.form.email = ''
        self.form.rolle = 'ROLE_USER'
        self.form.boxNo = ''
        self.form.password = ''
        self.formError = null
        self.toast.success('Ein neuer Benutzer wurde angelegt.')
        self.$router.push({ path: '/benutzer/uebersicht' })
      }).catch((error) => {
        self.formError = error.response.data
      })
    }
  }
}
</script>
